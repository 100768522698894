<template>
  <b-container>
    <div class="side-bar">
      <b-row>
        <b-col cols="12">
          <div class="sidebar">
            <b-card
              header-tag="header"
              footer-tag="footer"
            >
              <template #header>
                <div class="widget">
                  <div class="wtitle">
                    TIN TỨC MỚI NHẤT
                  </div>
                </div>

              </template>
              <ul class="ul-tintuc">
                <li
                  v-for="item in newsLatest"
                  class="li-tintuc"
                >
<!--                  <span @click="showDetailNews(item.id)">{{ item.title }}</span>-->
                  <b-link :to="{ name: 'newsDetail', params: { newsId: item.id } }">{{item.title}}</b-link>
                </li>
              </ul>
            </b-card>
            <b-card
              header-tag="header"
              footer-tag="footer"
            >
              <template #header>
                <div class="widget">
                  <div class="wtitle">
                    TIN TỨC NỔI BẬT
                  </div>
                </div>

              </template>

              <ul class="ul-tintuc">
                <li
                  v-for="item in newsHot"
                  class="li-tintuc"
                >
<!--                  <span @click="showDetailNews(item.id)">{{ item.title }}</span>-->
                  <b-link :to="{ name: 'newsDetail', params: { newsId: item.id } }">{{item.title}}</b-link>
                </li>
              </ul>
            </b-card>
          </div>
        </b-col>
      </b-row>
    </div>
  </b-container>

</template>

<script>
import {
  BCard, BCol, BRow, BContainer, BLink,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import { getUser } from '@/auth/utils'

export default {
  name: 'SideBarNews',
  components: {
    BCard,
    BCol,
    BRow,
    BContainer,
    BLink,
  },
  props: {
    categoryId: {
      // eslint-disable-next-line no-undef
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      newsId: '',
      newsLatest: [],
      newsHot: [],
      user: getUser(),
    }
  },

  async created() {
    await this.getInitData()
  },

  computed: {
    // calculate data from init data
    ...mapGetters({
      news: 'article/articlesAll',
      newsHL: 'article/articlesHL',
    }),
  },
  watch: {
    // listening change data init
    'categoryId': async function (val, oldVal) {
      await this.getInitData()
    },
  },
  methods: {
    // handle even
    ...mapActions({
      getNews: 'article/getAllArticles',
      getNewsHL: 'article/getArticlesHL',
      getArticleById: 'article/getArticleById',
    }),

    async getInitData() {
      const objSend = {
        categoryId: this.categoryId ? this.categoryId : null,
        search: '',
        status: 1,
        currentPage: 1,
        itemsPerPage: 1000,
        organizationId: this.user.orgId
      }

      await this.getNews(objSend)
      this.newsLatest = this.news.slice(0, 5)
      await this.getNewsHL(objSend)
      this.newsHot = this.newsHL.slice(0, 5)
    },

    async showDetailNews(newsId) {
      this.$emit('showDetailNews', newsId)
    },
  },
}
</script>

<style scoped>

.widget .wtitle {
  margin: 16px 0 12px;
  position: relative;
  font-size: 20px;
  display: inline-block;
  line-height: normal;
}
.widget .wtitle:after {
  content: "";
  height: 2px;
  background: #7367f0;
  position: absolute;
  left: 0;
  width: 100%;
  bottom: -5px;
}
.widget .wcontent a {
  display: block;
  color: #4f4f4f;
  line-height: 26px;
  font-size: 16px;
  margin-bottom: 5px;
}
.ul-tintuc {
  padding: 0px 15px;
}
.ul-tintuc .li-tintuc {
  padding-bottom: 5px;

}
.ul-tintuc .li-tintuc::marker {
  padding-bottom: 5px;
}
.li-tintuc {
  cursor: pointer;
}
.li-tintuc:hover {
  color: #5b98ea;
}
.li-tintuc a {
  color: black;
}

.wtitle {
  font-weight: 600;
}
.card-header {
  padding: 0.5rem 1.5rem;
}
</style>
